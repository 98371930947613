@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root {
  --default-font-family: 'Roboto', sans-serif;
}

html {
  font-size: 16px;
  height: 100%;
  width: 100vw;
  max-width: 100vw;
  overflow-x:hidden;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--default-font-family);
  box-sizing: border-box;
  height: 100%;
  max-width: 100vw;
  width: 100vw;
  overflow-x:hidden;
}

.side-menu-item-container-active {
  background: linear-gradient(to right,#3996C8 0%, #3996C8 2%, #b8def3 2%,#eaf3f7 30%, #fff 100%);

}

.side-menu-item-container {
  background: white;
}

.side-menu-item-text-active {
  color: #3996C8;
  font-weight: 600;
}

.side-menu-item-text {
  color: #363636;
  font-weight:400;
}

.header-width {
  max-width: calc(100vw- 18.5rem);
  overflow-x: hidden;
}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgb(0,0,0,0.15);
}

.scale-in-ver-top {
	-webkit-animation: scale-in-ver-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-ver-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

.tooltip {
  height: 2.2rem;
  padding-top: 0.4rem;
  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  position: relative;
  border:1px solid rgba(0,0,0,0.1);
  border-radius: 10px;
  font-size: small
}

.tooltip:after {
  content: "";
  position: absolute;
  right: 20px;
  bottom: -8px;
  border-top: 8px dotted rgba(0,0,0,0.1);
  border-right:8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: none;
}